import React, { useState, useEffect, useRef } from 'react';
import aboutImage from '../assets/about.png';
import Duckmeme from '../assets/duck_meme.png';

function About() {
    // State to track scroll position
    const [scrollY, setScrollY] = useState(0);
    // Ref for the image element
    const imageRef = useRef(null);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            setScrollY(window.scrollY);
            // You might want to adjust this based on how fast or slow you want the animation
            const jump = Math.sin(scrollY * 0.01) * 10; // Simple jump effect
            if (imageRef.current) {
                imageRef.current.style.transform = `translateY(${jump}px)`;
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollY]); // Dependency array to avoid unnecessary re-renders

    return (
        <section className="about-section py-[130px]">
            <div className="container">
                <div className="about-content flex sm:flex-row flex-col p-6 sm:p-[40px]">

                    <img
                        src={Duckmeme}
                        alt="Floop World"
                        ref={imageRef}
                        style={{
                            transition: 'transform 0.3s ease',
                        }}
                    />

                    <div>
                        <h2 className="about-title align-right">ABOUT</h2>
                        <p className="about-paragraph align-right">“Just a quirky friendly goose on solana”


                            Floop can be whatever it wants to be!</p>
                        <div className="button-container align-right">
                            <button className="telegram-button" onClick={() => window.open('https://t.me/+r0SJYPR2w7kzM2Y0', '_blank')}>
                                TELEGRAM
                            </button>
                            <a href="https://dexscreener.com/solana/c2nzbhock5ix5rfwj4wr2htwfzgnmnayup7chm8v4geg" target="_blank" rel="noopener noreferrer">
                                <button className="buy-now-button">
                                    BUY NOW
                                </button>
                            </a>
                        </div>
                    </div>
                    {/* More content about the community */}
                </div>
            </div>
        </section>
    );
}

export default About;