import React from 'react';
import footer from '../assets/footer.png';
import duck from '../assets/duck.png';
import snowImage1 from '../assets/snow.png';
import snowImage2 from '../assets/snow2.png';
import { keyframes } from '@emotion/react';
import { FaTelegramPlane, FaTwitter } from 'react-icons/fa';

// Keyframes for the duck animation
const popAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
  }
`;

function Footer() {
    const styles = {
        footer: {
            backgroundImage: `url(${footer})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            padding: '20px',
            alignItems: 'center',
            position: 'relative',
            width: '100%',
        },
        text: {
            color: 'white',
        },
        duckImage: {
            // maxWidth: '350px',
            // width: '100%',
            animation: `${popAnimation} 3s ease-in-out infinite`,
        },

        joinCommunityContainer: {
            transform: 'translateX(-50%)',
            textAlign: 'center',
            padding: '20px',



        },
        communityTitle: {
            color: '#95CA62',
            fontWeight: 'bold',
        },
        icon: {
            fontSize: '7em',
            margin: '0 10px',
            color: '#000',
            cursor: 'pointer',
            transition: 'color 0.3s ease',
            position: 'relative',
        },

        icons: {
            fontSize: '7em',
            margin: '0 10px',
            color: '#000',
            cursor: 'pointer',
            transition: 'color 0.3s ease',
            position: 'relative',
        }
    };

    // Function to open the respective social media in a new tab
    const openSocial = (url) => {
        window.open(url, '_blank');
    };

    return (
        <footer style={styles.footer}>
            <div style={styles.overlay}></div>
            <div
                className="absolute top-0 left-0 animate-snow-move-right"
                style={{
                    backgroundImage: `url(${snowImage1})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '150px',
                    height: '150px',
                    zIndex: 1,
                }}
            ></div>
            <div
                className="absolute top-0 right-0 animate-snow-move-left"
                style={{
                    backgroundImage: `url(${snowImage2})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '150px',
                    height: '150px',
                    zIndex: 1,
                }}
            ></div>


            <div className='text-center mb-[184px] relative'>
                <div>
                    <img className='absolute left-[50px] sm:left-0' src={duck} style={styles.duckImage} alt="duck" />
                </div>
                <div className='mt-[70px]'>
                    <h2 className='mb-[155px] leading-[100%] sm:text-[120px] text-[60px]' style={styles.communityTitle}><p>JOIN OUR</p>
                        <p>COMMUNITY</p>
                    </h2>
                </div>
                <div className="flex justify-center">
                    <div>
                        <FaTelegramPlane
                            style={styles.icon}
                            onClick={() => openSocial('https://t.me/+r0SJYPR2w7kzM2Y0')}
                            onMouseOver={e => e.target.style.color = '#0088CC'}
                            onMouseOut={e => e.target.style.color = '#000'}
                        />
                    </div>
                    <div>
                        <FaTwitter
                            style={styles.icons}
                            onClick={() => openSocial('https://x.com/flooponsol?s=21')}
                            onMouseOver={e => e.target.style.color = '#1DA1F2'}
                            onMouseOut={e => e.target.style.color = '#000'}
                        />
                    </div>
                </div>
            </div>

            <p className='text-lg sm:text-[35px] text-center' style={styles.text}>© 2024 Floop world</p>

        </footer>
    );
}

export default Footer;