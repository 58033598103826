import React from 'react';
import sliderr from '../assets/sliderr.png';
// Adjust the path according to where your Slide component is located

function Sliderr() {
    return (
        <section className="sliderr-section">
            <div className="sliderr-content">
                <h2 className="floop-text">
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                </h2>
            </div>
            <img src={sliderr} alt="Sliderr Image" className="sliderr-image" />

        </section>
    );
}

export default Sliderr;