import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import Header from "./pages/Header";
import Hero from "./pages/Hero";
import Slide from "./pages/Slide";
import About from "./pages/About";
import Slider from "./pages/Slider";
import Tokenomics from "./pages/Tokenomics";
import Sliderr from "./pages/Sliderr";
import Buy from "./pages/Buy";
import Sliderrr from "./pages/Sliderrr";
import Footer from "./pages/Footer";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="overflow-hidden">
              <Hero />
              <Slide />
              <About />
              <Slider />
              <Tokenomics />
              <Sliderr />
              <Buy />
              <Sliderrr />
              <Footer />
            </div>
          }
        />
        <Route />
      </Routes>
    </Router>
  );
}

export default App;
