import React from 'react';
import slider from '../assets/slider.png';
// Adjust the path according to where your Slide component is located

function Slider() {
    return (
        <section className="slider-section">
            <div className="slider-content">
                <h2 className="floop-text">
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                </h2>
            </div>
            <img src={slider} alt="Slider Image" className="slider-image" />

        </section>
    );
}

export default Slider;