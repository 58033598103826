import React, { useState, useEffect, createRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import herobg from '../assets/herobg.png';
import snowImage1 from '../assets/snow.png';
import snowImage2 from '../assets/snow2.png';
import floop from '../assets/floop.png';
import world from '../assets/world.png';
import { FaBell } from 'react-icons/fa';
import Logo from '../components/Logo';

function Hero() {
    const [isCopied, setIsCopied] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText('0xEE1A834C0A8KF8X8X8X98XHUR010094F180');
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    };

    const styles = {
        container: {
            position: 'relative',
            width: '1256px',
            maxWidth: '95%',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // padding: '10px 20px',
            border: '4px solid black',
            top: '40px',
            borderRadius: '427px',
            zIndex: '999',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            gap: '17px',
            width: '100%',
            height: '100%',

        },
        button: {
            backgroundColor: '#FFF0B9',
            color: 'black',
            border: '2px solid black',
            padding: '5px 10px',
            borderRadius: '8px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        },
        socialLinks: {
            width: '100%',
            height: '100%'
        },
        logo: {
            width: '50px',
            height: '50px',
        },
        title: {
            color: 'white',
            fontSize: '24px',
            fontWeight: 'bold',
            marginRight: '12px',
        },
        link: {
            color: 'black',
            textDecoration: 'none',
            fontSize: '35px',
            flex: '1 1 0%',
            height: '100%',
            display: "flex",
            alignItems: 'center',
            justifyContent: "center"
        },
        bellIcon: {
            cursor: 'pointer',
            fontSize: '24px',
            color: '#FF0000',
        },
        audioPlayer: {
            display: 'none',
        },
    };

    const audioRef = createRef();

    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.play().catch(error => {
                if (error.name === "NotAllowedError") {
                    console.log("Autoplay was prevented. Click the bell to play audio.");
                } else {
                    console.error("Error playing audio:", error.message);
                }
            });
            // Note: This doesn't actually set isPlaying to true if autoplay fails
            // User interaction will still be needed to start playing
            setIsPlaying(true);
        }
    }, []);

    const handleBellClick = () => {
        const audio = audioRef.current;
        if (audio) {
            if (audio.paused) {
                audio.play().catch(error => {
                    console.error("Failed to play audio:", error);
                });
                setIsPlaying(true);
            } else {
                audio.pause();
                setIsPlaying(false);
            }
        }
    };

    const [isOpen, setIsOpen] = useState(false);
    return (
        <section
            className="relative w-full bg-center bg-no-repeat sm:h-auto h-screen"
            style={{
                backgroundImage: `url(${herobg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}
        >
            <style jsx>{`
                @media (max-width: 768px) {
                    div {
                        background-size: 80% auto;
                    }
                }
            `}</style>

            <div
                className="absolute top-0 left-0 animate-snow-move-right"
                style={{
                    backgroundImage: `url(${snowImage1})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '150px',
                    height: '150px',
                    zIndex: 1,
                }}
            ></div>

            <div
                className="absolute top-0 right-0 animate-snow-move-left"
                style={{
                    backgroundImage: `url(${snowImage2})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '150px',
                    height: '150px',
                    zIndex: 1,
                }}
            ></div>

            <div style={styles.container} className='sm:h-[101px] h-[70px]'>
                <div style={styles.header}>
                    <div className='sm:pl-[51px] sm:pr-[32px] pr-[0px] pl-[24px]'>
                        <Logo />
                        <nav style={{ position: 'absolute', right: '0', top: '0' }}>
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                    color: 'black',
                                    padding: '10px'  // Add padding for better touch target
                                }}
                            >
                                ☰
                            </button>
                            {isOpen && (
                                <div style={{
                                    position: 'absolute',
                                    top: 'calc(100% + 10px)', // Adjust as necessary for your design
                                    right: '0', // Align to the right
                                    zIndex: '10',
                                    width: '200px', // Or however wide you want it
                                    borderRadius: '8px',
                                    backgroundColor: 'white', // Optional: set a background for the dropdown
                                    boxShadow: '0 2px 5px rgba(0,0,0,0.3)' // Optional: add a shadow
                                }}>
                                    <a
                                        href="/floop-secret"
                                        style={{
                                            display: 'block',
                                            padding: '10px 15px',
                                            textAlign: 'left', // Align text to the left
                                            textDecoration: 'none',
                                            fontSize: '16px',
                                            backgroundColor: '#f8f9fa',
                                            color: 'black',
                                            borderBottom: '1px solid #e9ecef'
                                        }}
                                    >
                                        Floopville
                                    </a>
                                    <a
                                        href="/coming-soon"
                                        style={{
                                            display: 'block',
                                            padding: '10px 15px',
                                            textAlign: 'left', // Align text to the left
                                            textDecoration: 'none',
                                            fontSize: '16px',
                                            backgroundColor: '#e0f2e0',
                                            color: 'black'
                                        }}
                                    >
                                        Coming Soon
                                    </a>
                                </div>
                            )}
                        </nav>
                        <style jsx>{`
                @media (min-width: 768px) {
                    nav div {
                        display: none; // Hide the dropdown menu on desktop
                    }
                }
            `}</style>
                    </div>
                    <div style={styles.socialLinks} className='hidden sm:flex'>
                        <a href="https://www.dextools.io/app/en/hot-pairs" style={{ ...styles.link, backgroundColor: '#FFECA5' }}>Dextools</a>
                        <a href="https://x.com/flooponsol?s=21" style={{ ...styles.link, backgroundColor: '#95CA62' }}>Twitter</a>
                        <div className='h-full '>
                            <div className='rounded-r-[200px]' style={{ ...styles.link, backgroundColor: '#FABA00' }}>
                                <a href="https://t.me/+r0SJYPR2w7kzM2Y0" className=' px-6'>Telegram</a>

                                <a
                                    href="https://dexscreener.com/solana/c2nzbhock5ix5rfwj4wr2htwfzgnmnayup7chm8v4geg
"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={styles.button}
                                    className='mr-10'
                                >
                                    BUY NOW
                                </a>


                            </div>
                        </div>
                        {/* <ReactAudioPlayer
                            src="/toy-ducks.mp3"
                            ref={audioRef} // Assuming you've created a ref for this
                            autoPlay
                            controls={false} // To hide browser controls
                            loop
                            style={{ display: 'none' }} // Hide the audio element
                        /> */}
                        <FaBell className='fixed bottom-[50px] right-[50px]' style={styles.bellIcon} onClick={handleBellClick} />
                        <audio ref={audioRef} style={styles.audioPlayer} loop>
                            <source src="/toy-ducks.mp3" type="audio/mpeg" />
                        </audio>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="hero-container">
                    <div className="hero-image-container">
                        <img
                            src="floop.png"
                            alt="FLOOP stylized image"
                            className="hero-image"
                        />
                    </div>


                    <div className="address-container mb-[69px] px-3">
                        <input
                            type="text"
                            value="7dWKxPV9wBCYrquxd1gS8oPxWAjdBUDCSZH663yRky1n"
                            className="addresfs-input"
                            disabled
                        />
                        <button
                            onClick={handleCopy}
                            className="copy-button"
                            title={isCopied ? "Copied!" : "Copy Address"}
                        >
                            <FontAwesomeIcon icon={faCopy} className="copy-icon" />
                        </button>
                    </div>

                    <div className="action-buttons-container px-3">
                        <a href="https://t.me/+r0SJYPR2w7kzM2Y0" target="_blank" rel="noopener noreferrer">
                            <button className="telegram-button">
                                TELEGRAM
                            </button>
                        </a>
                        <a href="https://dexscreener.com/solana/c2nzbhock5ix5rfwj4wr2htwfzgnmnayup7chm8v4geg" target="_blank" rel="noopener noreferrer">
                            <button className="buy-now-button">
                                BUY NOW
                            </button>
                        </a>
                        <a href="https://x.com/flooponsol?s=21" target="_blank" rel="noopener noreferrer">
                            <button className="twitter-button">
                                TWITTER
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <style jsx>{`
                @keyframes moveRight {
                    0% { transform: translateX(0); }
                    100% { transform: translateX(100vw); }
                }
                @keyframes moveLeft {
                    0% { transform: translateX(0); }
                    100% { transform: translateX(-100vw); }
                }

                .animate-snow-move-right {
                    animation: moveRight 15s linear infinite;
                }
                .animate-snow-move-left {
                    animation: moveLeft 15s linear infinite;
                }
            `}</style>
        </section>
    );
}

export default Hero;