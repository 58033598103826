import React from 'react';
import buyImage from '../assets/buy.png';
import wallet from '../assets/wallet.png';
import solana from '../assets/solana.png';
import swap from '../assets/swap.png';

const howTos = [
    {
        title: '1. WALLET',
        image: '/duck1.png',
        desc: 'Connect your Wallet '
    },
    {
        title: '2. Get some solana',
        image: '/duck2.png',
        desc: 'Fund your wallet  '
    },
    {
        title: '3. Swap $sol >$Floop',
        image: '/duck3.png',
        desc: 'Become a holder of $Floop'
    }
]

function Buy() {
    const styles = {
        buySection: {
            backgroundImage: `url(${buyImage})`,
            backgroundSize: 'cover', // Scales the image to cover the entire container
            backgroundPosition: 'center', // Centers the image
            backgroundRepeat: 'no-repeat', // Prevents the image from repeating
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // padding: '20px',
            // top: '-95px',
            position: 'relative',
        },
        title: {
            color: '#FEED00',
            stroke: '#000000',
            stroke: 'outside', // Assuming you want white text for contrast
            fontSize: '6em', // Adjust as needed
            textAlign: 'center',
            padding: '90px', // Space around the title
            borderRadius: '10px',
            maxWidth: '80%',
            position: 'relative',
        },
        walletImage: {
            maxWidth: '1600px', // Limit the width of the image
            width: '60%',
            height: 'auto',
            borderRadius: '10px',
            position: 'relative',
        },
        h3: {
            fontWeight: 'bold', // Bold for h3
            marginBottom: '10px',
            color: 'white',
            position: 'relative',
            fontSize: '1.2em',
        },
        p: {
            color: 'black', // Changed to black as per your request
            marginTop: '0',
            fontWeight: 'normal', // 'regular' is not a standard CSS value, 'normal' is
            position: 'relative',
        },

        solanaImage: {
            maxWidth: '1600px', // Limit the width of the image
            width: '60%',
            height: 'auto',
            borderRadius: '10px',
            position: 'relative',
            left: '110px', // Optional: gives the image rounded corners
        },
        h4: {
            fontWeight: 'bold', // Bold for h3
            marginBottom: '10px',
            color: 'white',
            position: 'relative',
            fontSize: '1.2em',
            left: '130px', // Spacing between h3 and p
        },
        C: {
            color: 'black', // Changed to black as per your request
            marginTop: '0',
            fontWeight: 'normal', // 'regular' is not a standard CSS value, 'normal' is
            position: 'relative',
        },

        swapImage: {
            maxWidth: '250px', // Limit the width of the image
            height: 'auto',
            borderRadius: '10px',
            position: 'relative',

        },
        h5: {
            fontWeight: 'bold', // Bold for h3
            marginBottom: '10px',
            color: 'white',
            position: 'relative',
            fontSize: '1.2em',
        },
        D: {
            color: 'black', // Changed to black as per your request
            marginTop: '0',
            fontWeight: 'normal', // 'regular' is not a standard CSS value, 'normal' is
            position: 'relative',
        },
    };



    return (
        <section style={styles.buySection} className='sm:py-[50px] py-[100px] sm:h-[800px] h-auto'>
            <div className="flex flex-wrap relative sm:justify-between justify-center gap-[50px]">
                {
                    howTos.map((how, i) => (
                        <div className='how-to-card z-10 relative mb-[120px] sm:mb-0' key={i}>
                            <img className='absolute translate-y-[-130px] top-0' src={how.image} alt="" />
                            <h4 className='mt-[108px] mb-[28px]'>{how.title}</h4>
                            <p >{how.desc}</p>
                        </div>))
                }

                <img className='absolute z-0 left-[200px]' src="/line.png" alt="" />
            </div>
        </section>
    );
}

export default Buy;