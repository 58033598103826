import React from 'react';
import sliderrr from '../assets/sliderrr.png';
// Adjust the path according to where your Slide component is located

function Sliderrr() {
    return (
        <section className="sliderrr-section">
            <div className="sliderrr-content">
                <h2 className="floop-text">
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                    <span className="yellow-dash">-</span>
                    <span>$Floop</span>
                </h2>
            </div>
            <img src={sliderrr} alt="Sliderrr Image" className="sliderrr-image" />

        </section>
    );
}

export default Sliderrr;