import React from 'react';
import tokenomicsImage from '../assets/tokenbg.png';
import image1 from '../assets/image1.png'; // Example image paths
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';

function Tokenomics() {
    const descriptions = [
        "LIQ. BURN",
        "BASED",
        "TOTAL SUPPLY"
    ];

    const styles = {
        tokenomicsSection: {
            backgroundImage: `url(${tokenomicsImage})`,
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            // height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            padding: '20px',
            position: 'relative',
            // top: '-95px',
        },
        content: {
            maxWidth: '800px',
            padding: '20px',
            borderRadius: '10px',
            margin: "0px auto"
        },
        tokenTitle: {
            color: '#FFECA5',
            fontWeight: 'bold',
            textAlign: 'center',
            /* width and color */

            /* TOKENOMICS */


            // marginTop: '-790px', // Spacing from the top
            // marginBottom: '-530px', // Spacing from the content below
        },
        imageContainer: {
            display: 'flex',
            flexDirection: 'row', // Ensure images are in a row
            justifyContent: 'space-around', // Evenly space images
            alignItems: 'center',
            width: '100%',
            marginBottom: '50px',


        },
        section: { // Using a common style for all sections
            flex: '1',
            textAlign: 'center',
            position: 'relative',
        },
        image: {
            // width: '350px',
            // height: '150%',
            // maxWidth: '500px',
            // position: 'relative',
            // top: '200px',


        },
        text: {
            marginTop: '10px',
            fontWeight: 'bold',
            position: 'relative',
            color: '#F2D59B',

        },
        percentageTitle: {
            color: 'white',
            fontWeight: 'bold',
        },

    };

    return (
        <section style={styles.tokenomicsSection}>
            <h2 style={styles.tokenTitle} className='fancy-text my-[70px] text-[52px] sm:text-[120px]'>1 BILLION TOTAL SUPPLY</h2>
            {/* 
<div style={styles.content}>
    <div style={styles.imageContainer}>
        <div style={styles.section}>
            <img src={image1} style={styles.image} alt={`Feature 1`} />
            <p style={styles.percentageTitle} className='text-xl sm:text-[2em]'>{`100%`}</p>
            <p style={styles.text}  className='text-xl sm:text-[2em]'>PRESALE</p>
        </div>

        <div style={styles.section}>
            <img src={image2} style={styles.image} alt={`Feature 2`} />
            <p style={styles.percentageTitle} className='text-xl sm:text-[2em]'>{`100%`}</p>
        </div>

        <div style={styles.section}>
            <img src={image3} style={styles.image} alt={`Feature 3`} />
            <p style={styles.percentageTitle} className='text-xl sm:text-[2em]'>{`100%`}</p>
            <p style={styles.text}  className='text-xl sm:text-[2em]'>TOTAL SUPPLY</p>
        </div>
    </div>
*/}
            {/* Tokenomics Explanation */}
            {/* <div style={styles.tokenomicsExplanation}>
                    <p style={styles.explanationText}>

                    </p>
                </div> */}


        </section>
    );
}

export default Tokenomics;